.App {
  text-align: center;
}

.main-app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.main {
  margin-top: 50px;
  flex-grow: 1; /* Allows main content to expand and push the footer down */
  display: flex;
}

.main > * {
  flex-grow: 1; /* Ensure that the component inside .main also expands fully */
}

header, footer {
  /* Additional styling as needed */
}