.card-container {
    perspective: 1000px; /* This makes flipping the card to have depth */
    width: 100%;
    height: 100%;
    margin-top: 3px;
}

.card {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: white;
    border-radius: 5px;

    transform-style: preserve-3d; /* allow children to exist in 3d */
    transition: transform 0.4s;
}

.card:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
}

.front, .back {
    backface-visibility: hidden; /* hides the back face (reverse side) when the element is turned in 3D space */
    position: absolute; /* This stack both elements on top of each other */
    width: 100%;
    height: 100%;
}

.front .image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 90%;
    height: 90%;
    transform: translate(5%, 4%);
}

.back {
    transform: rotateY(180deg); /* the back element's "visible side" is intially facing away from the viewer, thus hidden */
    /* background-color: red; */
}

.back .description {
    color: black;
    background-color: white;
    padding: 10px;
}

.back .description a {
    color: initial;
}

.card-container.flipped .card {
    transform: rotateY(180deg);
}