.experience {
    position: relative;
    top: 0;
    width: 100%;
    background-color: white;
}

.experience-item {
    position: relative;
    left: -100%;
    width: 350px;
    height: 200px;
}

.animation-left-active {
    animation: appearFromLeft 0.49s ease-out forwards;
    left: -100%;
}

.animation-right-active {
    animation: appearFromRight 0.49s ease-out forwards;
    left: 100%;  /* Added to specify initial position */
}

@keyframes appearFromLeft {
    0% { left: -100% }
    100% { 
        left: 15%; 
    }
}

@keyframes appearFromRight {
    0% { left: 100% }
    100% { 
        left: calc(85% - 350px);
    }
}

.flipping-card {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    background-color: blue;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

