.exercises {
    display: flex;
    background-color: #F0F8FF;
    text-align: center;
}

.exercises nav {
    width: 120px;
}

.exercises nav .link {
    text-decoration: none;
}

.exercises nav .link button:hover {
    background-color: #00308F;
    color: white;
}

.exercises nav .link button {
    background-color: #6CB4EE;
    border: none;
    width: 100%;
    height: 45px;
    cursor: pointer;
}

.exercises .content {
    flex-grow: 1;
    background-color: black;
    color: white;
}