.skills {
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    top: 0;
    width: 100%;
    background-color: gray;
}

.skills-container {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    background-color: white;
}

.progress-bar-container {
    width: 100%;
    background-color: gray;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  
.progress-bar {
    height: 8px;
    background-color: yellow;
    border-radius: 10px;
    transition: width 1s ease-out;
}

.progress-bar-tags {
    width: 100%;
}

.progress-bar-tags .percentage {
    display: inline-block;
    text-align: end;
    transition: width 1s ease-out;
}

.progress-bar-tags .name {
    display: inline-block;
    width: 70px;
}