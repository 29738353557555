.main-nav {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: #F8F8F8;
    z-index: 100;
}

.main-nav .nav-links {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.main-nav .link {
    text-decoration: none;
}

.main-nav button {
    background-color: transparent;
    border: none;
    width: 80px;
    height: 50px;
    cursor: pointer;
}

.main-nav button:hover {
    background-color: gray;
    color: white;
}