.login {
    position: relative;
    text-align: center;
    color: white;
    background-color: cyan;
}

.form {
    position: absolute;
    display: inline-block;
    width: 300px;
    background-color: #F0F8FF;
    text-align: center;
    border-radius: 5px;
    color: black;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    padding: 40px;
    padding-right: 80px;
}

.form label {
    display: inline-block;
    width: 140px;
    text-align: end;
    /* background-color: red; */
}

.form input {
    margin-left: 5px;
}

.form button {
    cursor: pointer;
}

.signup {
    border: none;
    background-color: transparent;
    text-decoration-line: underline;
}


