.introdiction-wrapper {
    position: relative;
    top: 0;
    height: 100vh;
    overflow: hidden;
    background-color: red;
    background-color: black;
}

.selfie {
    position: fixed;
    top: 0;
    left: 100%;
    background-image: url('../../../public/Najash.png');
    background-size: cover;
    background-color: black;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    animation: appearRightToCenter 1.5s cubic-bezier(0.1, 1, 0.1, 1) forwards;
}

@supports (background-image: url('../../../public/Najash.webp')) {
    .selfie {
      background-image: url('../../../public/Najash.webp');
    }
}

/* Do not stretch the selfie more passing this width */
@media (min-width: 1350px) {
    .selfie {
      background-size: 1350px auto;
    }
}

/* Selfie mobile animation */
@keyframes appearRightToCenter {
    100% { left: 0%; }
}

/* Selfie desktop animation */
@media (min-width: 431px) {
    @keyframes appearRightToCenter {
        100% { left: 25%; }
    }
}

.introduction {
    position: absolute;
    font-family: 'Raleway', sans-serif;
    text-align: end;
    color: white;
    top: 15%;
    left: -300px;
    height: 500px;
    width: 550px;
    opacity: 0;
    animation: appearLeftToCenter 1.1s ease forwards;
    z-index: 1;
    /* background-color: green; */
}

.introduction h2, .introduction h4 {
    margin: 0;
    margin-right: 10px;
}

.introduction h2 strong {
    font-size: 46px;
}

.introduction ul {
    list-style: none;
    color: #404040;
    font-family: 'PT Sans Narrow';
    font-size: 18px;
    margin-top: 50px;
}
  
.introduction li {
    position: relative;
    left: -350px;
    height: 30px;
    line-height: 30px;
    margin: 20px 0;
    opacity: 0;
    animation: liAppearLeftToCenter 0.6s ease-out forwards;
}

.introduction li span {
    background-color: #F8F8FF;
    padding: 5px 10px;
    border-top: 3px solid ghostwhite;
    border-left: 10px solid red;
}

.introduction .social-networks {
    position: relative;
}

.introduction .social-networks-wrapper {
    position: absolute;
    top: 100vh;
    font-size: 16px;
    text-align: center;
    color: white;
    right: 0;
    animation: appearFromBotttom 0.8s ease-out forwards;
    animation-delay: 0.8s; 
}

.introduction .social-networks a i {
    line-height: 50px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 10px;
    transition: transform 0.3s ease;
}

.introduction .social-networks a:visited {
    color: white;
}

.introduction .social-networks a i.fa-linkedin {
    border: 2px solid #0077B5;
}

.introduction .social-networks a i.fa-twitter {
    border: 2px solid #1DA1F2;
}

.introduction .social-networks a i.fa-facebook {
    border: 2px solid #4267B2;
}

.introduction .social-networks a i.fa-instagram {
    border: 2px solid #E1306C;
}

.introduction .social-networks a i.fa-youtube {
    border: 2px solid #FF0000;
}

.introduction .social-networks a i.fa-stack-overflow {
    border: 2px solid orange;
}

.introduction .social-networks a i:hover {
    transform: rotate(-25deg);
    cursor: pointer;
}

@keyframes appearLeftToCenter {
    100% { 
        left: 0; 
        opacity: 1;
    }
}

/* Selfie desktop animation */
@media (min-width: 431px) {
    @keyframes appearLeftToCenter {
        100% { left: 5%; opacity: 1;}
    }
}

@keyframes liAppearLeftToCenter {
    100% { 
        left: -10px; 
        opacity: 1;
    }
}

@keyframes appearFromBotttom {
    0% { top: 100vh }
    25% { top: 0px }
    50% { top: 60px }
    75% { top: 35px }
    100% { top: 50px }
}

.introduction li:nth-child(1) { animation-delay: 0s; }
.introduction li:nth-child(2) { animation-delay: 0.1s; }
.introduction li:nth-child(3) { animation-delay: 0.2s; }
.introduction li:nth-child(4) { animation-delay: 0.3s; }
.introduction li:nth-child(5) { animation-delay: 0.4s; }
.introduction li:nth-child(6) { animation-delay: 0.5s; }